import { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { CaretRight, CaretLeft } from '@phosphor-icons/react'
import { urlCore } from '../config'

function NextArrow(props) {
  let dataStore = useSelector((state) => state.dataStore);
  let colorFondoSubNav = dataStore?.ecommerce_colorFondoSubNav === "#454545" ? "#0661f4" : dataStore?.ecommerce_colorFondoSubNav
  const { onClick } = props
  return (
    <div
      style={{color:colorFondoSubNav}}
      className="absolute right-[2vw] top-[50%] p-2 rounded-full bg-black text-blue hover:cursor-pointer hover:bg-white hover:text-black duration-200"
      onClick={onClick}
    >
      <CaretRight size={28} />
    </div>
  )
}

function PrevArrow(props) {
  let dataStore = useSelector((state) => state.dataStore);
  let colorFondoSubNav = dataStore?.ecommerce_colorFondoSubNav === "#454545" ? "#0661f4" : dataStore?.ecommerce_colorFondoSubNav
  const { onClick } = props
  return (
    <div
      style={{color:colorFondoSubNav}}
      className={`absolute z-10 left-[2vw] top-[50%] p-2 rounded-full bg-black text-blue hover:cursor-pointer hover:bg-white hover:text-black duration-200`}
      onClick={onClick}
    >
      <CaretLeft size={28} />
    </div>
  )
}

export default function SliderBannners() {
  const [banners, setBanners] = useState([])

  let dataStore = useSelector((state) => state.dataStore);
  let configData = useSelector((state) => state.configData)

  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    draggable: false,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }

  //w original (showing images in landing page)
  // useEffect(() => {
  //   ;(async () => {
  //     if (dataStore) {
  //       let banners = Object.keys(dataStore)
  //         .filter((key) => key.includes('imagen') && !key.includes('logo'))
  //         .map((key) => dataStore[key])
  //         .filter((banner) => banner !== '')
  //       setBanners(banners)
  //     }
  //   })()
  // }, [dataStore])

  //f 
  useEffect(() => {
    ;(async () => {
      if (dataStore) {
        let banners = Object.keys(dataStore)
          .filter((key) => 
            key.includes('imagen') && 
            !key.includes('logo') &&
            !key.includes('imagen1_informacion2') &&
            !key.includes('imagen2_informacion2')
          )
          .map((key) => dataStore[key])
          .filter((banner) => banner !== '')
        setBanners(banners)
      }
    })()
  }, [dataStore])

  return (
    <Slider {...settings}>
      {banners?.map((b, i) => (
        <img
          key={i}
          className="h-[400px] object-cover"
          src={`${urlCore}archivos/info/cart/${configData.tiendaid}/${b}`}
          alt={b}
        />
      ))}
    </Slider>
  )
}
