import { Link } from 'react-router-dom'
import { UseRemoveTildes } from '../utilities/UseRemoveTildes'
import { useSelector } from 'react-redux'

export default function BrandCategoryCard({ data, type, actualPage = 1, typography = {} }) {
  let dataStore = useSelector((state) => state.dataStore)
  let colorFondoSubNav = dataStore?.ecommerce_colorFondoSubNav === "#454545" ? "#0661f4" : dataStore?.ecommerce_colorFondoSubNav

  return (
    <Link
      className="relative flex-shrink-0 rounded-[5px] w-[50%] sm:w-[32%] lg:w-[220px] text-center group border-1 border-white sm:border-black hover:border-gray-02 duration-200"
      to={`/productos/${UseRemoveTildes(type)}/${data.name
        ?.replace(/\s+/g, '-').replace(/\//g, '-')
        .toLowerCase()}/${data?.id}/1`}
    >
      <p 
      style={{color:colorFondoSubNav}} 
      // className="overflow-hidden max-lg:text-[12px] rounded-b-[5px] flex items-center justify-center w-full sticky sm:absolute top-0 h-[45px] bg-black text-blue font-bold px-1 py-1 text-[14px] group-hover:text-white duration-200"
      className={`${typography.title} overflow-hidden max-lg:text-[12px] rounded-b-[5px] flex items-center justify-center w-full sticky sm:absolute top-0 h-[45px] bg-black text-blue px-1 py-1 text-[14px] group-hover:text-white duration-200`}
      
      >
        {data?.name}
      </p>
      {
        data.image === "sin imagen" ?
        <div         style={{
          backgroundColor: colorFondoSubNav
        }} 
        // className="h-[150px] sm:h-[220px] text-[20px] p-3 object-cover rounded-[5px] w-[100%] bg-blue text-white flex justify-center items-center font-bold"
        className={`${typography.title} h-[150px] sm:h-[220px] text-[20px] p-3 object-cover rounded-[5px] w-[100%] bg-blue text-white flex justify-center items-center`}
        >{data.name.toUpperCase()}</div> :
        <img
        className="h-[150px] sm:h-[220px] object-cover rounded-[5px] w-[100%]"
        src={data.image}
      />
      }
    </Link>
  )
}
