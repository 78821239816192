import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import SearchBar from "./SearchBar";
import SubNavBar from "./SubNavBar";

import {
  List,
  ShoppingBag,
  UserCircle,
  WhatsappLogo,
} from "@phosphor-icons/react";
import { ProfileMenu } from "./ProfileMenu";

import { Toaster, toast } from "sonner";

import { setCatalogProducts } from "./../actions/index.js";

import { urlCore } from "../config";

import useComponentVisible from "../utilities/UseComponentVisible.jsx";
import { useEffect, useState } from "react";
import { getProducts, getTotalProducts } from "../methods/index.js";
import logo_default from "../media/logo_develshops.png";
import { Help } from "./Help.jsx";

import { logOut, setClientSession } from "./../actions/index.js";
import { useNavigate } from "react-router-dom";
import {
  logOut as logOutMethod,
  obtenterClientSession,
} from "./../methods/index.js";
import { getContrastingTextColor, getTypographyClass } from "../methods/utilsMethods.js";

export default function NavBar({ showLanding }) {
  const dispatch = useDispatch();
  const location = useLocation();

  let clientSession = useSelector((state) => state.clientSession);
  let user = useSelector((state) => state.user);
  let totalProducts = useSelector((state) => state.totalProducts);
  let dataStore = useSelector((state) => state.dataStore);
  let configData = useSelector((state) => state.configData);
  let default_onlyread = dataStore?.default_onlyread;
  let dataStore2 = useSelector((state) => state.dataStore2);
  const typography = getTypographyClass(configData);

  // console.log("DATA STORE >>>>>", dataStore);
  // console.log('DATAStore 2 >>>>>>', dataStore2)

  //f colors
  let colorNavBar = dataStore?.ecommerce_colorPrimario || "#BF407C";
  let colores = JSON.parse(localStorage.getItem("dataStore")) || "";

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  let colorFondoSubNav =
    dataStore?.ecommerce_colorFondoSubNav === "#454545"
      ? "#0661f4"
      : dataStore?.ecommerce_colorFondoSubNav;

  const [colorHover, setColorHover] = useState({
    email: false,
    cart: false,
    editar: false,
    pedidos: false,
    salir: false,
    ingresar: false,
    registrar: false,
  });

  const textColor = getContrastingTextColor(colorNavBar);

  const navigate = useNavigate();

  // determines if SearchBar should be visible
  const shouldShowSearchBar = () => {
    // show SearchBar if:
    // 1. user is logged in, OR
    // 2. default_productos_login is "0"
    return user || dataStore?.default_productos_login === "0";
  };

  function handleMyOrders() {
    setIsComponentVisible(false);
    navigate("./mis-pedidos");
  }

  function handleEditProfile() {
    setIsComponentVisible(false);
    navigate("./editar-perfil");
  }

  //w original
  // async function handleLogOut() {
  //   console.log("Logging out...");
  //   const isLogOut = await logOutMethod(user.id)
  //   console.log("Logout success:", isLogOut);
  //   if (isLogOut) {
  //     dispatch(setClientSession(await obtenterClientSession()))
  //     dispatch(logOut())
  //     setIsComponentVisible(false)
  //     toast(
  //       <div className="flex flex-col gap-2 text-[13px]">
  //         <p>Te has deslogueado exitosamente</p>
  //       </div>
  //     )
  //     navigate('/')
  //   }
  // }

  //f
  async function handleLogOut() {
    try {
      // calls logout API
      const isLogOut = await logOutMethod(user.id);

      if (isLogOut) {
        // clears localStorage
        localStorage.removeItem("user");
        localStorage.removeItem("clientSession");
        localStorage.removeItem("totalProducts");

        // updates Redux
        dispatch(logOut());

        toast(
          <div className="flex flex-col gap-2 text-[13px]">
            <p>Te has deslogueado exitosamente</p>
          </div>
        );

        const newClientSession = await obtenterClientSession();
        dispatch(setClientSession(newClientSession));

        // closes profile menu!!
        setIsComponentVisible(false);
        // console.log('ISCOMPONENT VISIBLE?>>>', isComponentVisible)

        navigate("/");
      } else {
        console.error("Error al cerrar sesión. Por favor intente nuevamente.");
      }
    } catch (error) {
      console.error("Logout error:", error);
      console.error("Error al cerrar sesión. Por favor intente nuevamente.");
    }
  }

  useEffect(() => {
    (async () => {
      let clientId = user ? user.id : clientSession;
      await getTotalProducts(clientId);
      setColorHover({
        email: false,
        cart: false,
        editar: false,
        pedidos: false,
        salir: false,
        ingresar: false,
        registrar: false,
      });
    })();
  }, []);

  return (
    <div className="flex flex-col text-[13px] sticky top-0 z-20 w-full flex-start">
      <Toaster />
      <div
        style={{
          backgroundColor: colores.ecommerce_colorPrimario,
        }}
      >
        <div className="w-full sm:w-[80vw] mx-auto p-2">
          <h1 className="hidden">Develone</h1>
          <div
            ref={ref}
            className="flex items-center justify-between flex-wrap"
          >
            <Link className="sm:hidden relative group text-white" to="/carrito">
              <ShoppingBag className="group-hover:text-blue" size={36} />
              {/* NUMBER OF PRODUCTS ADDED TO THE CART */}
              <div className="flex justify-center items-center absolute w-[26px] h-[26px] bottom-[-10px] right-[-10px] border-2 bg-black border-white rounded-full group-hover:text-black group-hover:bg-blue group-hover:border-black">
                <span className="text-[10px] group-hover:font-bold">
                  {totalProducts}
                </span>
              </div>
            </Link>
            <Link to="/">
              {/* //ff PROVISORIO: desde ADMINISTRACION/Mitienda/RedesSociales > 
              -si hay logo && campo 'Telegram' contiene texto > se muestra el LOGO
              -si campo 'Telegram' esta vacio > se muestra nombre Tienda
              -si no hay nombre de tienda, logo default
          */}
              {/* //f fixed */}
              {dataStore?.imagenlogo && !dataStore?.telegram_url ? (
                <img
                  className="w-[110px] object-cover"
                  src={`${urlCore}/archivos/logos/cart/${configData?.tiendaid}/${dataStore.imagenlogo}`}
                  alt="Logo Develone"
                />
              ) : dataStore?.telegram_url && dataStore2?.nombre ? (
                <span
                  className="text-xl font-bold text-white px-2 py-1 rounded bg-gradient-to-r from-blue-500 to-green-500 shadow-lg"
                  style={{
                    display: "inline-block",
                    textTransform: "uppercase",
                    letterSpacing: "0.1em",
                  }}
                >
                  {dataStore2.nombre}
                </span>
              ) : (
                <img
                  className="w-[110px] object-cover"
                  src={logo_default}
                  alt="Logo Develone"
                />
              )}
              {/* //w original */}
              {/* {
              dataStore?.imagenlogo ?
              <img
                className="w-[60px] object-cover"
                src={`${urlCore}/archivos/logos/cart/${configData?.tiendaid}/${dataStore?.imagenlogo}`}
                alt="Logo Develone"
              /> :
              <img
                className="w-[60px] object-cover"
                src={logo_default}
                alt="Logo Develone"
              /> 
            } */}
            </Link>
            <div className="hidden sm:flex">
            {shouldShowSearchBar() && <SearchBar />}
              {/* <SearchBar /> */}
            </div>

            <div className="hidden sm:flex items-center text-white gap-2">
              <div className="relative">
                {/* AYUDA */}
                <Help dataStore={dataStore} />
              </div>
              {user ? (
                <ProfileMenu key={user ? user.id : "guest"} user={user} />
              ) : default_onlyread === "0" ? (
                <div className="relative">
                  <div
                    // className="font-bold flex flex-col justify-center items-center py-1 px-2 rounded-[5px] hover:cursor-pointer duration-200 min-w-[110px]"
                    className={`flex flex-col justify-center items-center py-1 px-2 rounded-[5px] hover:cursor-pointer duration-200 min-w-[110px] font-bold ${typography.title}`}
                    onClick={() => setIsComponentVisible(!isComponentVisible)}
                  >
                    <UserCircle size={36} />
                    Mi cuenta
                  </div>
                  {/* MENU PROFILE */}
                  {isComponentVisible && (
                    <div className="flex flex-col items-center gap-2 text-white z-10 absolute top-[70px] bg-white p-2 rounded shadow-xl border-1 border-gray-01 w-[100%] min-w-[120px]">
                      <Link
                        className="border-1 border-black bg-black p-2 text-sm rounded-[5px] w-full hover:bg-white0 duration-200"
                        to="iniciarsesion"
                      >
                        <button
                          className="border-1 border-black bg-black px-2 text-sm rounded-[5px] w-full hover:bg-white0 duration-200"
                          onClick={() =>
                            setIsComponentVisible(!isComponentVisible)
                          }
                          style={{
                            color: colorHover.ingresar && colorFondoSubNav,
                          }}
                          onMouseEnter={() =>
                            setColorHover({
                              email: false,
                              cart: false,
                              editar: false,
                              pedidos: false,
                              salir: false,
                              ingresar: true,
                              registrar: false,
                            })
                          }
                        >
                          Ingresar
                        </button>
                      </Link>
                      <Link
                        className="border-1 border-black bg-black p-2 text-sm rounded-[5px] w-full hover:bg-white0 duration-200"
                        to="registrarse"
                      >
                        <button
                          className="border-1 border-black bg-black px-2 text-sm rounded-[5px] w-full hover:bg-white0 duration-200"
                          onClick={() =>
                            setIsComponentVisible(!isComponentVisible)
                          }
                          style={{
                            color: colorHover.registrar && colorFondoSubNav,
                          }}
                          onMouseEnter={() =>
                            setColorHover({
                              email: false,
                              cart: false,
                              editar: false,
                              pedidos: false,
                              salir: false,
                              ingresar: false,
                              registrar: true,
                            })
                          }
                        >
                          Registrarse
                        </button>
                      </Link>
                    </div>
                  )}
                </div>
              ) : null}
              {default_onlyread === "0" ? (
                <Link className="relative group" to="/carrito">
                  <ShoppingBag size={36} />

                  <div className="flex justify-center items-center absolute w-[26px] h-[26px] bottom-[-10px] right-[-10px] border-2 bg-black border-white rounded-full group-hover:text-black group-hover:bg-white group-hover:border-black">
                    <span className="text-[10px] group-hover:font-bold">
                      {totalProducts}
                    </span>
                  </div>
                </Link>
              ) : null}
            </div>
            <List
              className="text-white sm:hidden mr-2 hover:cursor-pointer"
              size={32}
              onClick={() => setIsComponentVisible(!isComponentVisible)}
            />
            {/* <div className="flex sm:hidden w-[100%]">
              <SearchBar />
            </div> */}
            {/* Mobile SearchBar */}
          {shouldShowSearchBar() && (
            <div className="flex sm:hidden w-[100%]">
              <SearchBar />
            </div>
          )}
            {isComponentVisible && (
              <div
                style={{ color: colorHover.editar && colorFondoSubNav }}
                className="md:hidden lg:hidden flex flex-col items-center gap-2 text-white z-10 absolute top-[70px] bg-white p-2 right-0 rounded shadow-xl border-1 border-gray-01 w-[100%]"
              >
                {user ? (
                  <button
                    className="border-1 border-black bg-black p-2 text-sm rounded-[5px] w-full hover:bg-white0 duration-200"
                    onClick={() => handleEditProfile()}
                    style={{ color: colorHover.editar && colorFondoSubNav }}
                    onMouseEnter={() =>
                      setColorHover({
                        email: false,
                        cart: false,
                        editar: true,
                        pedidos: false,
                        salir: false,
                        ingresar: false,
                        registrar: false,
                      })
                    }
                  >
                    Editar perfil
                  </button>
                ) : (
                  <Link
                    className="border-1 border-black bg-black p-2 text-sm rounded-[5px] w-full hover:bg-white0 duration-200"
                    to="iniciarsesion"
                  >
                    <button
                      className="border-1 border-black bg-black text-sm rounded-[5px] w-full hover:bg-white0 duration-200"
                      onClick={() => setIsComponentVisible(!isComponentVisible)}
                      style={{ color: colorHover.editar && colorFondoSubNav }}
                      onMouseEnter={() =>
                        setColorHover({
                          email: false,
                          cart: false,
                          editar: false,
                          pedidos: false,
                          salir: false,
                          ingresar: true,
                          registrar: false,
                        })
                      }
                    >
                      Ingresar
                    </button>
                  </Link>
                )}
                {user ? (
                  <button
                    className="border-1 border-black bg-black p-2 text-sm rounded-[5px] w-full hover:bg-white0 duration-200"
                    onClick={() => handleMyOrders()}
                    style={{ color: colorHover.pedidos && colorFondoSubNav }}
                    onMouseEnter={() =>
                      setColorHover({
                        email: false,
                        cart: false,
                        editar: false,
                        pedidos: true,
                        salir: false,
                        ingresar: false,
                        registrar: false,
                      })
                    }
                  >
                    Mis pedidos
                  </button>
                ) : (
                  <Link
                    className="border-1 border-black bg-black p-2 text-sm rounded-[5px] w-full hover:bg-white0 hover:text-blue duration-200"
                    to="registrarse"
                  >
                    <button
                      className="border-1 border-black bg-black text-sm rounded-[5px] w-full hover:bg-white0 hover:text-blue duration-200"
                      onClick={() => setIsComponentVisible(!isComponentVisible)}
                    >
                      Registrarse
                    </button>
                  </Link>
                )}
                <button className="border-1 border-black bg-black p-2 text-sm rounded-[5px] w-full hover:bg-white0 hover:text-blue duration-200">
                  <Link
                    className="flex items-center justify-center"
                    to={dataStore?.url_whatsapp_api}
                    target="_blank"
                  >
                    <WhatsappLogo size={20} style={{ marginRight: 2 }} />
                    {dataStore?.url_whatsapp_api.split("/")[3]}
                  </Link>
                </button>
                <button className="border-1 border-black bg-black p-2 px-2 text-sm rounded-[5px] w-full hover:bg-white0 hover:text-blue duration-200">
                  {dataStore?.mail_direccion}
                </button>
                {user ? (
                  <button
                    className="border-1 border-black bg-black p-2 text-sm rounded-[5px] w-full hover:bg-white0 hover:text-blue duration-200"
                    onClick={handleLogOut}
                    style={{ color: colorHover.salir && colorFondoSubNav }}
                    onMouseEnter={() =>
                      setColorHover({
                        email: false,
                        cart: false,
                        editar: false,
                        pedidos: false,
                        salir: true,
                        ingresar: false,
                        registrar: false,
                      })
                    }
                  >
                    Salir
                  </button>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <SubNavBar /> */}
      {location.pathname !== "/iniciarsesion" &&
        location.pathname !== "/registrarse" &&
        !showLanding && <SubNavBar />}
    </div>
  );
}
