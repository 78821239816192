import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

export default function ProductMostSold({ product, typography }) {
  const [productNameUrl, setProductNameUrl] = useState()

  let dataStore = useSelector((state) => state.dataStore)
  let colores = JSON.parse(localStorage.getItem('dataStore')) || ''
  let colorFondoSubNav = dataStore?.ecommerce_colorFondoSubNav === "#454545" ? "#0661f4" : dataStore?.ecommerce_colorFondoSubNav

  const titleCase = (str) => {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    // Directly return the joined string
    return splitStr.join(' '); 
 }

  useEffect(() => {
    let newProductName = product.name.replace(/\//g, '-')
    setProductNameUrl(newProductName)
  }, [])

  return (
    <Link
      className="relative flex flex-col border-1 border-white sm:border-black flex-shrink-0 sm:rounded-[5px] h-[300px] w-[50%] sm:w-[32%] lg:w-[24%] xl:w-[220px] text-center hover:shadow-2xl hover:border-gray-03 duration-200"
      to={`/producto/${encodeURIComponent(productNameUrl)}/${product.id}`}
    >
      {
        product.image === "sin imagen" ?
        <div 
        // className="h-[150px] text-[18px] flex flex-grow w-[100%] bg-blue lex justify-center items-center text-white"  
        className={`h-[150px] text-[18px] flex flex-grow w-[100%] bg-blue justify-center items-center text-white ${typography.text}`}
        > 
        <p className="p-3">{product.name.toUpperCase()}</p>
        {
          product.precioPromo !== "0,00" &&
          <span style={{width:139, borderTopRightRadius:3}} 
          // className="bg-error absolute top-0 text-white right-0"
          className={`bg-error absolute top-0 text-white right-0 ${typography.title}`}
          >
          <span>OFERTA</span>
          </span>
        }
        </div> :
        <div className="h-[150px] text-[20px] flex flex-grow w-[100%]"
        >
        <img
          className="h-[190px] sm:h-[190px] object-cover rounded-[5px] w-[100%]"  
          src={product.image}
        />
        {
          product.precioPromo !== "0,00" &&
          <span style={{width:139, borderTopRightRadius:3}} 
          // className="bg-error absolute right-0 top-0 text-white"
          className={`bg-error absolute right-0 top-0 text-white ${typography.title}`}
          >
          <span>OFERTA</span>
          </span>
        }
        </div>
      }
      <div style={{backgroundColor: colores.ecommerce_colorTerciario, color:colorFondoSubNav}}  className="flex flex-col items-start text-left text-blue p-3 h-[130px] justify-between">
        <p 
        // className="text-[13px] text-left min-h-[45px] font-bold"
        className={`text-[13px] text-left min-h-[45px] ${typography.text}`}
        >
          {product.name?.length > 50
            ? titleCase(product.name.slice(0, 50)) + '...'
            : titleCase(product.name)}
        </p>
        <div>
        <p 
        // className={`font-bold text-[17px] ${product.precioPromo !== "0,00" && "line-through text-[15px]"}`}
        className={`${typography.title} text-[17px] ${product.precioPromo !== "0,00" && "line-through text-[15px]"}`}
        >${product.price}</p>
        {product.precioPromo !== "0,00" && <p 
        // className="font-arial-black text-[17px]"
        className={`${typography.title} text-[17px]`}
        >${product.precioPromo}</p>}
        {
          product.es_digital === "0" && product.stock !== 0 ?
          <p 
          // className="text-[12px]"
          className={`text-[12px] ${typography.text}`}
          >Stock disponible: {product.stock}</p> : null
        }
        </div>
      </div>
    </Link>
  )
}
