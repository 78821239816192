import { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { Link, useNavigate } from 'react-router-dom'

import { UserCircle } from '@phosphor-icons/react'

import { signUp } from '../methods'

import { logIn as logInAction } from './../actions/index'

import { Toaster, toast } from 'sonner'

import SignUpStore from './SignUpStore'

import { UseScrollToTop } from './../utilities/UseScrollToTop'

import { ModalMaintenance } from './ModalMaintenance'

export default function SignUp() {

  let dataStore = useSelector((state) => state.dataStore)
  let enMantenimiento = dataStore?.default_mantenimiento  
  let colorFondoSubNav = dataStore?.ecommerce_colorFondoSubNav === "#454545" ? "#0661f4" : dataStore?.ecommerce_colorFondoSubNav

  const navigate = useNavigate()

  let sessionHash = useSelector((state) => state.sessionHash)
  let configData = useSelector((state) => state.configData)

  let dispatch = useDispatch()

  const [user, setUser] = useState({
    name: '',
    email: '',
    password: '',
    repeatedPassword: '',
    store: {
      name: '',
      type: '',
      dni: '',
    },
  })

  const [showStoreRegister, setShowStoreRegister] = useState(false)

  const [error, setError] = useState(false)

  async function handleSignUp() {
    if (
      user.name === '' ||
      user.email === '' ||
      user.password === '' ||
      user.repeatedPassword === ''
    ) {
      toast(
        <div className="flex flex-col gap-2 text-[13px]">
          <p>Debe llenar todos los campos por favor</p>
        </div>
      )
      return
    }
    if (!user.email.includes('@')) {
      toast(
        <div className="flex flex-col gap-2 text-[13px]">
          <p>Ingrese un email valido</p>
        </div>
      )
      return
    }
    if (user.password.length < 4 || user.password !== user.repeatedPassword) {
      toast(
        <div className="flex flex-col gap-2 text-[13px]">
          <p>
            Revise que las contraseñas sean de 4 caracteres o más y que sean
            iguales
          </p>
        </div>
      )
      return
    }
    const data = await signUp(user, sessionHash, configData.api_key)
    if (typeof data === 'object') {
      localStorage.setItem('user', JSON.stringify(data))
      dispatch(logInAction(data))
      navigate('/')
    } else {
      toast(
        <div className="flex flex-col gap-2 text-[13px]">
          <p>{data}</p>
        </div>
      )
      return
    }
  }

  let sucursal_madre = JSON.parse(localStorage.getItem('dataStore')) || ''

  return (
    <div className="w-[80vw] mx-auto mt-12">
      <ModalMaintenance
        show={enMantenimiento}
      />
      <UseScrollToTop />
      <Toaster />
      <p className="text-[13px]">
        INICIO / <span className="font-bold text-[16px]">REGISTRARSE</span>
      </p>
      <div className="sm:w-[40vw] mx-auto my-20 flex justify-center flex-col items-center gap-4">
        <UserCircle
          className="bg-black p-2 rounded-full text-white"
          size={60}
        />
        <label
          className="w-full flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue"
          htmlFor="name"
        >
          NOMBRE
          <input
            className="text-gray-03 text-[13px] border-1 border-gray-03 rounded-[5px] p-2 pl-4 focus:outline-0 focus-within:text-black"
            id="name"
            placeholder="Juan Perez"
            type="text"
            name="name"
            value={user.name}
            onChange={(e) =>{
              const regex = /\d/
              let aux = regex.test(e.target.value)
              setError(aux)
              setUser({
                ...user,
                [e.target.name]: e.target.value,
              })
            }}
          />
        {
          error && <p className='text-error text-[11px]'>Debe ingresar solo letras.</p>
        }
        </label>
        <label
          className="w-full flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue"
          htmlFor="email"
        >
          EMAIL
          <input
            className="text-gray-03 text-[13px] border-1 border-gray-03 rounded-[5px] p-2 pl-4 focus:outline-0 focus-within:text-black"
            id="email"
            placeholder="ejemplo@ejemplo.com"
            type="email"
            name="email"
            value={user.email}
            onChange={(e) =>
              setUser({
                ...user,
                [e.target.name]: e.target.value,
              })
            }
          />
        </label>
        <label
          className="w-full flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue"
          htmlFor="password"
        >
          CONTRASEÑA
          <input
            className="text-gray-03 text-[13px] border-1 border-gray-03 rounded-[5px] p-2 pl-4 focus:outline-0 focus-within:text-black"
            id="password"
            type="password"
            name="password"
            value={user.password}
            onChange={(e) =>
              setUser({
                ...user,
                [e.target.name]: e.target.value,
              })
            }
          />
        </label>
        <label
          className="w-full flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue"
          htmlFor="repeatedPassword"
        >
          REPETIR CONTRASEÑA
          <input
            className="text-gray-03 text-[13px] border-1 border-gray-03 rounded-[5px] p-2 pl-4 focus:outline-0 focus-within:text-black"
            id="repeatedPassword"
            type="password"
            name="repeatedPassword"
            value={user.repeatedPassword}
            onChange={(e) =>
              setUser({
                ...user,
                [e.target.name]: e.target.value,
              })
            }
          />
        </label>
        {
          sucursal_madre !== '' && 
          sucursal_madre.sucursal_madre === "1" &&
          sucursal_madre.tiendafree === "1" ?
          <label className="flex w-full items-center gap-2">
          <input
            type="checkbox"
            id="topping"
            name="topping"
            value="registerStore"
            onChange={() => setShowStoreRegister(!showStoreRegister)}
          />
          <p>
            Registrar una tienda{' '}
            <span className="text-blue text-sm">
              (Por un período de prueba de 30 días)
            </span>
          </p>
        </label> : null
        }
        {showStoreRegister && <SignUpStore user={user} setUser={setUser} />}
        <div className="flex flex-col items-center gap-4 mt-4">
          <button
            style={{color:colorFondoSubNav}}
            className="text-blue text-[16px] py-2 px-20 bg-black rounded-[5px] hover:text-white duration-200"
            onClick={() => handleSignUp()}
            disabled={error}
          >
            REGISTRARSE
          </button>
          <p className="text-[13px]">
            Si ya tenés una cuenta{' '}
            <Link
              style={{color:colorFondoSubNav}}
              className="p-2 bg-black text-blue rounded-[5px] hover:text-white duration-200"
              to="/iniciarsesion"
            >
              ingresa acá
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}
