//f with mobile categories
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { CaretRight, List } from "@phosphor-icons/react";
import CarouselCategory from "./CarouselCategory";
import { UseRemoveTildes } from "../utilities/UseRemoveTildes";
import { getCategoriesWithoutPaginationA } from "../methods";
import LazyLoadingMenu from "./LazyLoadingMenu";
import { getTypographyClass } from "../methods/utilsMethods";

// const ButtonLink = ({ to, label }) => (
//   <NavLink
//     // className={({ isActive }) =>
//     //   isActive
//     //     ? "gap-2 rounded-b-[5px] p-2 bg-black text-white"
//     //     : "p-2 rounded-b-[5px] hover:text-white hover:bg-black duration-200"
//     // }
//     className={({ isActive }) =>
//       `${typography.title} ${
//         isActive
//           ? "gap-2 rounded-b-[5px] p-2 bg-black text-white"
//           : "p-2 rounded-b-[5px] hover:text-white hover:bg-black duration-200"
//       }`
//     }
//     to={to}
//   >
//     {label}
//   </NavLink>
// );

export default function SubNavBar() {
  const user = useSelector((state) => state.user);
  const configData = useSelector((state) => state.configData);
  const dataStore = useSelector((state) => state.dataStore);
  const menu_desplegable = dataStore?.menu_desplegable;
  const typography = getTypographyClass(configData);

  const [categories, setCategories] = useState([]);

  const [subCategories, setSubCategories] = useState([]);
  const [openCategorias, setOpenCategorias] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [timeoutId, setTimeoutId] = useState(null);
  const [ok, setOk] = useState(false);
  // New state to track expanded categories on mobile
  const [expandedCategories, setExpandedCategories] = useState({});

  const ButtonLink = ({ to, label }) => (
    <NavLink
      // className={({ isActive }) =>
      //   isActive
      //     ? "gap-2 rounded-b-[5px] p-2 bg-black text-white"
      //     : "p-2 rounded-b-[5px] hover:text-white hover:bg-black duration-200"
      // }
      className={({ isActive }) =>
        `${typography.title} ${
          isActive
            ? "gap-2 rounded-b-[5px] p-2 bg-black text-white"
            : "p-2 rounded-b-[5px] hover:text-white hover:bg-black duration-200"
        }`
      }
      to={to}
    >
      {label}
    </NavLink>
  );

  //f
  const fetchCategories = async (page) => {
    if (!ok) {
      const response = await getCategoriesWithoutPaginationA(page);
      setCategories(response.data);
      setOk(true);
    }
  };

  // initial fetch
  useEffect(() => {
    if (configData && !ok) {
      fetchCategories(1);
      setOk(true);
    }
  }, [configData, ok]);

  const menuRef = useRef(null);

  let botones = [];
  if (menu_desplegable === "1") {
    if (
      dataStore?.default_categorias === "1" &&
      dataStore?.default_marcas === "1"
    ) {
      botones = [
        { to: "/categorias/1", label: "CATEGORÍAS" },
        { to: "/marcas/1", label: "MARCAS" },
        { to: "/mis-pedidos", label: "MIS PEDIDOS" },
        { to: "/tiendas", label: "TIENDAS" },
      ];
    } else if (
      dataStore?.default_categorias === "1" &&
      dataStore?.default_marcas === "0"
    ) {
      botones = [
        { to: "/categorias/1", label: "CATEGORÍAS" },
        { to: "/mis-pedidos", label: "MIS PEDIDOS" },
        { to: "/tiendas", label: "TIENDAS" },
      ];
    } else if (dataStore?.default_marcas === "1") {
      botones = [
        { to: "/marcas/1", label: "MARCAS" },
        { to: "/mis-pedidos", label: "MIS PEDIDOS" },
        { to: "/tiendas", label: "TIENDAS" },
      ];
    }
  }

  const handleMouseEnter = () => {
    if (timeoutId) clearTimeout(timeoutId);
    setOpenCategorias(true);
  };

  const handleMouseLeave = () => {
    const id = setTimeout(() => {
      setOpenCategorias(false);
      setSubCategories([]);
    }, 300);
    setTimeoutId(id);
  };

  const handleToggleMenu = () => {
    setOpenCategorias((prev) => !prev);

    // Ensure the menu renders on the first click if it's not visible
    if (!openCategorias) {
      setTimeout(() => setOpenCategorias(true), 0);
    }
  };

  // New function to handle mobile category clicks
  const handleMobileClick = (categoryId) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [categoryId]: !prev[categoryId],
    }));
  };

  const handleGetSubCategories = (data) => {
    setCategoryName(data.categoria);
    setSubCategories(data.subcategorias);
  };

  //f original getAllcategories useEffect
  // useEffect(() => {
  //   if (configData && !ok) {
  //     (async () => {
  //       const allCategories = await getAllCategoriesA();
  //       setCategories(allCategories);
  //       setOk(true);
  //       // console.log('ALL CATEGORIES', allCategories)
  //     })();
  //   }
  // }, [configData, ok]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        openCategorias
      ) {
        setOpenCategorias(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [openCategorias]);

  let colorFondoSubNav =
    dataStore?.ecommerce_colorFondoSubNav === "#454545"
      ? "#0661f4"
      : dataStore?.ecommerce_colorFondoSubNav;
  let colorTextoSubNav =
    dataStore?.ecommerce_colorTextoSubNav === "#3c3c3c"
      ? "white"
      : dataStore?.ecommerce_colorTextoSubNav;
  let colorMenuDesplegable = dataStore?.ecommerce_colorMenuDesplegable;
  let colorTextoMenuDesplegable =
    dataStore?.ecommerce_colorTextoMenuDesplegable === "#3c3c3c"
      ? "white"
      : dataStore?.ecommerce_colorTextoMenuDesplegable;

  if (menu_desplegable !== "1" || !categories.length) {
    return null;
  }

  return (
    <div
      style={{ backgroundColor: colorFondoSubNav }}
      className={`p-1 pt-0 text-[12px] h-10 ${
        menu_desplegable === "1" && "h-14"
      }`}
    >
      <div className="flex gap-4 w-full mx-auto font-bold items-center">
        {menu_desplegable === "1" ? (
          <div
            className="flex items-center p-3"
            style={{ color: colorTextoSubNav }}
          >
            <div
              ref={menuRef}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className="relative"
            >
              <button
                onClick={handleToggleMenu}
                // className="flex items-center rounded-[5px] p-1 hover:text-white hover:bg-black duration-200 text-[16px]"
                className={`${typography.title} flex items-center rounded-[5px] p-1 hover:text-white hover:bg-black duration-200 text-[16px]`}
              >
                <List size={20} style={{ marginRight: 10 }} />
                CATEGORÍAS
              </button>
              {/* //w original  */}
              {openCategorias && (
                <div className="absolute top-full left-0">
                  <LazyLoadingMenu
                    colorMenuDesplegable={colorMenuDesplegable}
                    colorTextoMenuDesplegable={colorTextoMenuDesplegable}
                    typography={typography}
                  />
                </div>
              )}
            </div>
            <CarouselCategory typography={typography} />
          </div>
        ) : (
          botones.map((boton, index) => {
            if (boton.label === "MIS PEDIDOS" && !user) return null;
            if (boton.label === "TIENDAS" && dataStore?.multisucursal === "0")
              return null;
            return <ButtonLink key={index} to={boton.to} label={boton.label} />;
          })
        )}
      </div>
    </div>
  );
}

//w original
// import { NavLink } from "react-router-dom";
// import { useSelector } from "react-redux";
// import useComponentVisible from "../utilities/UseComponentVisible";
// import {
//   getBrandsWithoutPagination,
//   getBrandsWithoutPaginationA,
//   getCategoriesWithoutPagination,
//   getCategoriesWithoutPaginationA,
//   getSubcategorias,
// } from "../methods";
// import { useEffect, useState } from "react";
// import { CaretDown, CaretRight, List } from "@phosphor-icons/react";
// import CarouselCategory from "./CarouselCategory";
// import { UseRemoveTildes } from "../utilities/UseRemoveTildes";

// const ButtonLink = ({ to, label }) => (
//   <NavLink
//     className={({ isActive }) =>
//       isActive
//         ? "gap-2 rounded-b-[5px] p-2 bg-black text-white"
//         : "p-2 rounded-b-[5px] hover:text-white hover:bg-black duration-200"
//     }
//     to={to}
//   >
//     {label}
//   </NavLink>
// );

// export default function SubNavBar() {
//   let user = useSelector((state) => state.user);
//   let configData = useSelector((state) => state.configData);
//   let dataStore = useSelector((state) => state.dataStore);

//   let menu_desplegable = dataStore?.menu_desplegable;
//   // console.log('MENU DESPLEGABLE', menu_desplegable)

//   //w original
//   // let botones =
//   // dataStore?.default_categorias === "1" && dataStore?.default_marcas === "1"
//   // ?   [
//   //   { to: "/categorias/1", label: "CATEGORÍAS" },
//   //   { to: "/marcas/1", label: "MARCAS" },
//   //   { to: "/mis-pedidos", label: "MIS PEDIDOS" },
//   //   { to: "/tiendas", label: "TIENDAS" },
//   // ] :
//   // dataStore?.default_categorias === "1" && dataStore?.default_marcas === "0"
//   // ?   [
//   //   { to: "/categorias/1", label: "CATEGORÍAS" },
//   //   { to: "/mis-pedidos", label: "MIS PEDIDOS" },
//   //   { to: "/tiendas", label: "TIENDAS" },
//   // ] :
//   // [
//   //   { to: "/marcas/1", label: "MARCAS" },
//   //   { to: "/mis-pedidos", label: "MIS PEDIDOS" },
//   //   { to: "/tiendas", label: "TIENDAS" },
//   // ]

//   //f with menu_desplegable is enabled

//   let botones = [];

//   if (menu_desplegable === "1") {
//     if (
//       dataStore?.default_categorias === "1" &&
//       dataStore?.default_marcas === "1"
//     ) {
//       botones = [
//         { to: "/categorias/1", label: "CATEGORÍAS" },
//         { to: "/marcas/1", label: "MARCAS" },
//         { to: "/mis-pedidos", label: "MIS PEDIDOS" },
//         { to: "/tiendas", label: "TIENDAS" },
//       ];
//     } else if (
//       dataStore?.default_categorias === "1" &&
//       dataStore?.default_marcas === "0"
//     ) {
//       botones = [
//         { to: "/categorias/1", label: "CATEGORÍAS" },
//         { to: "/mis-pedidos", label: "MIS PEDIDOS" },
//         { to: "/tiendas", label: "TIENDAS" },
//       ];
//     } else if (dataStore?.default_marcas === "1") {
//       botones = [
//         { to: "/marcas/1", label: "MARCAS" },
//         { to: "/mis-pedidos", label: "MIS PEDIDOS" },
//         { to: "/tiendas", label: "TIENDAS" },
//       ];
//     }
//   }

//   const [categories, setCategories] = useState([]);
//   const [subCategories, setSubCategories] = useState([]);
//   const [brands, setBrands] = useState([]);
//   const [openCategorias, setOpenCategorias] = useState(false);
//   const [openSubs, setOpenSubs] = useState(false);
//   const [categoryName, setCategoryName] = useState("");

//   const [openCategoriasResp, setOpenCategoriasResp] = useState(false);

//   async function handleGetSubCategories(data) {
//     setCategoryName(data.categoria);
//     setSubCategories(data.subcategorias);
//   }

//   const [page, setPage] = useState(1);

//   let colorFondoSubNav =
//     dataStore?.ecommerce_colorFondoSubNav === "#454545"
//       ? "#0661f4"
//       : dataStore?.ecommerce_colorFondoSubNav;
//   let colorTextoSubNav =
//     dataStore?.ecommerce_colorTextoSubNav === "#3c3c3c"
//       ? "white"
//       : dataStore?.ecommerce_colorTextoSubNav;

//   let colorMenuDesplegable = dataStore?.ecommerce_colorMenuDesplegable;
//   let colorTextoMenuDesplegable =
//     dataStore?.ecommerce_colorTextoMenuDesplegable === "#3c3c3c"
//       ? "white"
//       : dataStore?.ecommerce_colorTextoMenuDesplegable;

//   const [ok, setOk] = useState(false);

//   useEffect(() => {
//     if (configData) {
//       if (ok === false) {
//         (async () => {
//           let dataC = await getCategoriesWithoutPaginationA(1);
//           let dataB = await getBrandsWithoutPaginationA(1);
//           setCategories(dataC.data);
//           setBrands(dataB.data);
//         })();
//         setOk(true);
//       }
//     }
//   }, [configData]);

//   return (
//     <div
//       style={{
//         backgroundColor: colorFondoSubNav,
//       }}
//       className={`p-1 pt-0 text-[12px] h-10 ${
//         menu_desplegable === "1" && "h-14"
//       }`}
//     >
//       <div className="flex gap-4 w-full mx-auto font-bold items-center">
//         {menu_desplegable === "1" ? (
//           <div
//             className="flex items-center p-3"
//             style={{ color: colorTextoSubNav }}
//           >
//             {/* //w original > bugged hover */}
//             {/* <button
//             className="flex items-center rounded-[5px] p-1 hover:text-white hover:bg-black duration-200 text-[16px]"
//             onMouseOver={() => {
//               setOpenCategorias(!openCategorias)
//               if(!openCategorias === false){
//                 setSubCategories([])
//               }
//             }}
//             onClick={() => {
//               setOpenCategorias(!openCategorias)
//             }}
//             >

//               <List size={20} style={{marginRight:10}}/> CATEGORÍAS
//           </button> */}

//             {/* //f hover fixed */}
//             <button
//               className="flex items-center rounded-[5px] p-1 hover:text-white hover:bg-black duration-200 text-[16px]"
//               onMouseEnter={() => {
//                 setOpenCategorias(true); // Only open on hover
//                 setSubCategories([]); // Reset subcategories
//               }}
//               onMouseLeave={() => {
//                 setOpenCategorias(false); // Close when mouse leaves
//               }}
//               onClick={() => {
//                 setOpenCategorias(!openCategorias); // Allow toggle on click
//               }}
//             >
//               <List size={20} style={{ marginRight: 10 }} />
//               CATEGORÍAS
//             </button>

//             <CarouselCategory />
//           </div>
//         ) : (
//           botones.map((boton, index) => {
//             if (boton.label === "MIS PEDIDOS") {
//               if (!user) return null;
//             }
//             if (boton.label === "TIENDAS") {
//               if (dataStore?.multisucursal === "0") return null;
//             }
//             return <ButtonLink key={index} to={boton.to} label={boton.label} />;
//           })
//         )}
//       </div>

//       {openCategorias && (
//         <div
//           className="flex absolute w-[100%] max-md:top-[190px] min-md:top-[140px] left-0"
//           onMouseLeave={() => {
//             setOpenCategorias(false);
//             setSubCategories([]);
//           }}
//         >
//           <div
//             style={{
//               backgroundColor: colorMenuDesplegable,
//               color: colorTextoMenuDesplegable,
//             }}
//             className="max-md:w-[100%] w-[25%] flex flex-col z-10 bg-white p-4 rounded border-1 border-gray-01"
//           >
//             {categories.length > 0 &&
//               categories.map((c) =>
//                 Object.values(c.subcategorias).length > 0 ? (
//                   <div key={c.id}>
//                     <p
//                       onClick={() => {
//                         setOpenSubs(!openSubs);
//                         handleGetSubCategories({
//                           categoria: c.name,
//                           subcategorias: Object.values(c.subcategorias),
//                         });
//                       }}
//                       onMouseOver={() => {
//                         setSubCategories([]);
//                         handleGetSubCategories({
//                           categoria: c.name,
//                           subcategorias: Object.values(c.subcategorias),
//                         });
//                       }}
//                       style={{ color: colorTextoMenuDesplegable }}
//                       className="flex items-center justify-between rounded-[5px] text-black text-[14px] hover:bg-black hover:text-blue py-1 px-2 cursor-pointer"
//                     >
//                       {c.name} <CaretRight />
//                     </p>
//                     {categoryName === c.name && openSubs === true && (
//                       <div
//                         style={{
//                           backgroundColor: colorMenuDesplegable,
//                           color: colorTextoMenuDesplegable,
//                         }}
//                         className="min-md:hidden w-full flex flex-col z-10 bg-white p-2 right-30 rounded border-gray-01"
//                       >
//                         {c.subcategorias.map((c) => (
//                           <a
//                             style={{ color: colorTextoMenuDesplegable }}
//                             className="rounded-[5px] text-black text-[14px] hover:bg-black hover:text-blue py-1 px-2 cursor-pointer"
//                             href={`/productos/categorias/${categoryName.toLowerCase()}/${UseRemoveTildes(
//                               c.nombre.replace(/\s+/g, "-").replace(/\//g, "-")
//                             )}/${c.categoriaid}/${c.subcategoriaid}/1`}
//                           >
//                             {c.nombre}
//                           </a>
//                         ))}
//                       </div>
//                     )}
//                   </div>
//                 ) : (
//                   <a
//                     style={{
//                       backgroundColor: colorMenuDesplegable,
//                       color: colorTextoMenuDesplegable,
//                     }}
//                     className="rounded-[5px] text-black text-[14px] hover:bg-black hover:text-blue py-1 px-2 cursor-pointer"
//                     href={`/productos/categorias/${c.name
//                       ?.replace(/\s+/g, "-")
//                       .replace(/\//g, "-")
//                       .toLowerCase()}/${c.id}/1`}
//                   >
//                     {c.name}
//                   </a>
//                 )
//               )}
//           </div>
//           {subCategories.length > 0 && (
//             <div
//               style={{
//                 backgroundColor: colorMenuDesplegable,
//                 color: colorTextoMenuDesplegable,
//               }}
//               className="w-[20%] max-md:hidden flex flex-col z-10 bg-white p-4 right-30 rounded border-1 border-gray-01 w-[100%]"
//             >
//               {subCategories.length > 0 &&
//                 subCategories.map((c) => (
//                   <a
//                     style={{ color: colorTextoMenuDesplegable }}
//                     className="rounded-[5px] text-black text-[14px] hover:bg-black hover:text-blue py-1 px-2 cursor-pointer"
//                     href={`/productos/categorias/${categoryName.toLowerCase()}/${UseRemoveTildes(
//                       c.nombre.replace(/\s+/g, "-").replace(/\//g, "-")
//                     )}/${c.categoriaid}/${c.subcategoriaid}/1`}
//                   >
//                     {c.nombre}
//                   </a>
//                 ))}
//             </div>
//           )}
//         </div>
//       )}

//       {/* {openCategoriasResp && openCategorias === false &&(
//         <div className="flex absolute w-[100%] top-[180px] max-md:visible left-0" >
//           <div className="flex flex-col z-10 top-[120px] bg-white p-4 w-[100%]">
//           {
//             categories.length > 0 && categories.map((c) =>
//               Object.values(c.subcategorias).length > 0 ?
//               <div>
//                 <p
//                   onClick={() => {
//                     setOpenSubs(!openSubs)
//                     handleGetSubCategories({categoria: c.name, subcategorias: Object.values(c.subcategorias)})
//                   }}
//                   className="flex items-center justify-between rounded-[5px] text-black text-[14px] hover:bg-black hover:text-blue py-1 px-2 cursor-pointer"
//                   >{c.name} <CaretDown/>
//                 </p>
//                 {categoryName === c.name && openSubs === true &&(
//                   <div className="w-full flex flex-col z-10 bg-white p-4 right-30 rounded border-gray-01">
//                     {
//                       c.subcategorias.map((c) =>

//                         <a
//                           className="rounded-[5px] text-black text-[14px] hover:bg-black hover:text-blue py-1 px-2 cursor-pointer"
//                           href={`/productos/categorias/${categoryName
//                             .toLowerCase()}/${UseRemoveTildes(c.nombre.replace(/\s+/g, '-').replace(/\//g, '-'))}/${c.categoriaid}/${c.subcategoriaid}/1`}
//                         >
//                           {c.nombre}
//                         </a>

//                       )
//                     }
//                   </div>
//                 )}

//               </div>
//               :
//               <a
//                 className="rounded-[5px] text-black text-[14px] hover:bg-black hover:text-blue py-1 px-2 cursor-pointer"
//                 href={`/productos/categorias/${c.name
//                   ?.replace(/\s+/g, '-').replace(/\//g, '-')
//                   .toLowerCase()}/${c.id}/1`}
//               >
//                 {c.name}
//               </a>

//             )
//           }
//         </div>

//       </div>
//       )} */}
//     </div>
//   );
// }
