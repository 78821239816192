import { useEffect, useState, useRef } from 'react'

import { getBrandsWithoutPagination, getCategoriesWithoutPagination } from '../methods/index'

import { CaretRight, CaretLeft } from '@phosphor-icons/react'

import BrandCategoryCard from './BrandCategoryCard'

import { useSelector } from 'react-redux'
import { getTypographyClass } from '../methods/utilsMethods'

export default function CarouselBrandCategory({ titles }) {
  const sliderRef = useRef()

  let configData = useSelector((state) => state.configData)
  let dataStore = useSelector((state) => state.dataStore)
  let colorFondoSubNav = dataStore?.ecommerce_colorFondoSubNav === "#454545" ? "#0661f4" : dataStore?.ecommerce_colorFondoSubNav

  const [brands, setBrands] = useState([])
  const [whatIsShowing, setWhatIsShowing] = useState('Categorías')
  const [page, setPage] = useState(1)
  const [paginaUltima, setPaginaUltima] = useState(1)
  const [ok, setOK] = useState(false)

  const typography = getTypographyClass(configData)

  //f
  useEffect(() => {
    if (titles && titles.length > 0) {
      setWhatIsShowing(titles[0]);
      setOK(false); 
    }
  }, [titles]);

  //w original
  // useEffect(() => {
  //   if (configData) {
  //     if(dataStore){
  //       if(ok === false){
  //         if (whatIsShowing === 'Marcas') {
  //           ;(async () => {
  //             setPage(1)
  //             let  data  = await getBrandsWithoutPagination(1)
  //             setBrands(data.data)
  //             setPaginaUltima(data.paginaUltima)
  //           })()
  //         } else {
  //           ;(async () => {
  //             setPage(1)
  //             let  data  = await getCategoriesWithoutPagination(1)
  //             setBrands(data.data)
  //             setPaginaUltima(data.paginaUltima)
  //           })()
  //         }
  //         setOK(true)
  //       }
  //     }
  //   }
  // }, [whatIsShowing, configData, dataStore])

  //f 
  useEffect(() => {
    if (configData && dataStore && !ok) {
      const fetchData = async () => {
        setPage(1);
        const data = whatIsShowing === 'Marcas'
          ? await getBrandsWithoutPagination(1)
          : await getCategoriesWithoutPagination(1);
        setBrands(data.data);
        setPaginaUltima(data.paginaUltima);
      };
      fetchData();
      setOK(true);
    }
  }, [whatIsShowing, configData, dataStore, ok]);

  async function handleBackSlide(e) {
    sliderRef.current.scrollLeft -= sliderRef.current.clientWidth + 20
  }

  async function handleNextSlide(e) {
    if(page < paginaUltima){
      setPage(page+1)
      if (whatIsShowing === 'Marcas') {
        let  data  = await getBrandsWithoutPagination(page+1)
        setPaginaUltima(data.paginaUltima)
        setBrands(brands.concat(data.data))
      } else {
        let  data  = await getCategoriesWithoutPagination(page+1)
        setPaginaUltima(data.paginaUltima)
        setBrands(brands.concat(data.data))
      }
    }
    sliderRef.current.scrollLeft += sliderRef.current.clientWidth + 20
  }

  const handleGetCards = (title) => {
    setWhatIsShowing(title)
    setOK(false)
  }

  return (
    <div className="overflow-x-hidden">
      <div className="flex justify-end w-max my-[25px] gap-2 bg-black py-2 px-8 pl-20 rounded-r-[15px] text-white text-[16px]">
        {titles?.map((t, i) => (
          <button
            key={i}
            style={{color: t.toUpperCase() === whatIsShowing.toUpperCase() ? colorFondoSubNav : 'white'}}
            onClick={() => handleGetCards(t)}
            className={`${typography.title}`}
          >
            {t.toUpperCase()}
          </button>
        ))}
      </div>
      <div className="relative sm:w-[80vw] mx-auto">
        <CaretLeft
            style={{color:colorFondoSubNav}}
          className="bg-black p-[0.3rem] pl-0 w-[3em] sm:w-[2em] h-[2em] sm:p-1 rounded-r-[20px] sm:rounded-full text-blue absolute top-1/2 left-0 sm:left-[-15px] z-10 hover:cursor-pointer hover:text-black hover:bg-white duration-200"
          onClick={handleBackSlide}
          size={40}
        />
        <div
          className="flex sm:gap-[5px] lg:gap-[10px] justify-center overflow-x-hidden scroll-smooth py-1"
          ref={sliderRef}
        >
          {brands?.map((d) => (
            <BrandCategoryCard
              key={d.id}
              data={d}
              type={whatIsShowing.toLowerCase()}
              typography={typography}
            />
          ))}
        </div>
        <CaretRight
            style={{color:colorFondoSubNav}}
          className="bg-black p-[0.3rem] pr-0 w-[3em] sm:w-[2em] h-[2em] sm:p-1 rounded-l-[20px] sm:rounded-full text-blue absolute top-1/2 right-0 sm:right-[-15px] z-10 hover:cursor-pointer hover:text-black hover:bg-white duration-200"
          onClick={handleNextSlide}
          size={40}
        />
      </div>
    </div>
  )
}
